import React from 'react';
function MenuItemList({ data, onSelect, desktop }) {
    const handleProductClick = (product) => {
        onSelect(product);
    };

    let LongDescription = '';
    let LongDescriptionDesktop = '';
    return (
        <>
            {data.map(function (x, i) {
                if (x['Names'][0]['LongDescription'].length > 120) LongDescription = x['Names'][0]['LongDescription'].slice(0, 115).substr(0, x['Names'][0]['LongDescription'].slice(0, 115).lastIndexOf(" ")) + "...";
                else LongDescription = x['Names'][0]['LongDescription'] + ".";

                if (x['Names'][0]['LongDescription'].length > 140) LongDescriptionDesktop = x['Names'][0]['LongDescription'].slice(0, 135).substr(0, x['Names'][0]['LongDescription'].slice(0, 135).lastIndexOf(" ")) + "...";
                else LongDescriptionDesktop = x['Names'][0]['LongDescription'] + ".";

                if (x['Names'][0]['LongDescription'].length === 0) {
                    LongDescription = '';
                    LongDescriptionDesktop = '';
                }

                return (
                    <>
                        <div className='col-lg-3 col-6 px-1'>
                            <button key={i} className="list-group-item list-group-item-action d-flex gap-3 p-0 mb-3 rounded border border-0" onClick={() => handleProductClick(x)}>
                                <div className="card h-product-card border border-0">
                                    <img src={x['Images'][0]['Image1']} alt="twbs" className="card-img-top img-fluid"></img>
                                    <div className="card-body py-3 px-0">
                                        <h5 className="card-title text-center">{x['Names'][0]['DigitalName'].toUpperCase()}</h5>
                                        <p className="card-text px-4 text-center fs-8 lh-sm">{desktop ? (LongDescriptionDesktop) : (LongDescription)}</p>
                                    </div>
                                    <div className="card-footer bg-white">
                                        <div className="row justify-content-between">
                                            <div className="col-5 pe-0">
                                                <span className='card-title card-price'>{x['Prices'][0]['Price']} AED</span>
                                            </div>
                                            <div className="col-7 px-0 text-end">
                                                {/* <span className='text-cal'>000 cal</span>
                                                <img className="p-icon" src="../img/smoky/spicy.png" width="20" height="20" alt=''></img>
                                                <img className="p-icon" src="../img/smoky/vegan.png" width="20" height="20" alt=''></img>
                                                <img className="p-icon" src="../img/smoky/best.png" width="20" height="20" alt=''></img> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </>
                );
            })}

        </>
    )
}

export default MenuItemList