import 'bootstrap/dist/css/bootstrap.min.css'
import Scrollspy from 'react-scrollspy'
import React, { useState, useEffect, useRef } from 'react'
import CatMenuList from '../components/menu/category_menu_list'
import ShishaMenuModal from '../components/menu/menu_shisha_modal'

function Menu(slug) {
    const [loading, setLoading] = useState(true)
    const dataRefGen = useRef([])
    const dataRefCat = useRef([])
    const dataRefMenu = useRef([])
    const [useOnUpdate, setUseOnUpdate] = useState(true)
    const [desktop, setdesktop] = useState(false)
    const [showScrollButton, setShowScrollButton] = useState(false)
    const [isActiveMenu, setIsActiveMenu] = useState(1)
    const [showShishaModal, setShowShishaModal] = useState(false)


    useEffect(() => {
        console.log(isActiveMenu)
    }, [isActiveMenu]);
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            setShowScrollButton(true)
        } else {
            setShowScrollButton(false)
        }
    }
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const outlet_slug = slug['slug'];
        let windowWidth = window.innerWidth || document.documentElement.clientWidth;
        if (windowWidth > 640) setdesktop(true);
        else setdesktop(false);
        fetch('https://qrportalbackend.azurewebsites.net/generation/outlet/' + outlet_slug)
            .then(response => response.json())
            .then(data => {
                dataRefGen.current = data;
                const generation = dataRefGen.current[0]['Generation']
                fetch(`https://qrportalbackend.azurewebsites.net/category/outlet/${generation}`)
                    .then(response => response.json())
                    .then(data2 => {
                        dataRefCat.current = data2;
                    })
                    .catch(error => console.log(error))
                fetch(`https://qrportalbackend.azurewebsites.net/menu/outlet/${generation}/1`)
                    .then(response => response.json())
                    .then(data3 => {
                        dataRefMenu.current = data3;
                        setLoading(false);
                    })
                    .catch(error => console.log(error))
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line
    }, []);

    function handleupdate() {
        let isOffscreenHorizontally = false;
        const activeListItem = document.querySelector(".nav-item.active-item");
        const activeAnchor = activeListItem.querySelector("a");
        const elementRect = activeAnchor.getBoundingClientRect();
        const activeAnchorId = activeAnchor.id.replace('cat', '');
        let windowWidth = window.innerWidth || document.documentElement.clientWidth;
        if (windowWidth > 640) windowWidth = 700;
        else windowWidth = 0;
        isOffscreenHorizontally = elementRect.left < (50 + windowWidth) || elementRect.left > (320 + windowWidth);

        if (activeListItem.previousElementSibling == null) {
            const navContainer = document.querySelector('.nav');
            navContainer.scrollLeft = 0;
        }
        else if (isOffscreenHorizontally) {
            document.getElementById(activeAnchor.id).scrollIntoView({ inline: "center" });
            isOffscreenHorizontally = false;
        }
        if (activeAnchorId === '44') setIsActiveMenu(1)
        else if (activeAnchorId >= 52) setIsActiveMenu(2)
        else setIsActiveMenu(1)
    }

    function handleOnClick(event) {
        const catId = event.target.id.replace('cat', '')
        if (catId === '44') setIsActiveMenu(1)
        else if (catId >= 52) setIsActiveMenu(2)
        else setIsActiveMenu(1)
        setUseOnUpdate(false);
        setTimeout(() => {
            setUseOnUpdate(true)
        }, 2000);
    }

    function next() {
        const activeListItem = document.querySelector(".nav-item.active-item");
        var nextElement = activeListItem.nextElementSibling;
        if (nextElement) {
            const nextAnchor = nextElement.querySelector("a");
            const activeAnchorId = nextAnchor.id.replace('cat', '');
            activeListItem.classList.remove('active-item');
            nextElement.classList.add('active-item');
            nextAnchor.click();
            document.getElementById(nextAnchor.id).scrollIntoView({ inline: "center" });
            if (activeAnchorId === '44') setIsActiveMenu(1)
            else if (activeAnchorId >= 52) setIsActiveMenu(2)
            else setIsActiveMenu(1)
        }
    }

    function prev() {
        const activeListItem = document.querySelector(".nav-item.active-item");
        var prevElement = activeListItem.previousElementSibling;
        if (prevElement) {
            const prevAnchor = prevElement.querySelector("a");
            const activeAnchorId = prevAnchor.id.replace('cat', '');
            activeListItem.classList.remove('active-item');
            prevElement.classList.add('active-item');
            prevAnchor.click();
            document.getElementById(prevAnchor.id).scrollIntoView({ inline: "center" });
            if (activeAnchorId === '44') setIsActiveMenu(1)
            else if (activeAnchorId >= 52) setIsActiveMenu(2)
            else setIsActiveMenu(1)
        }
    }

    function handleActiveMenu(menu, id) {
        console.log("handleActiveMenu")
        document.getElementById(id).scrollIntoView({ inline: "center" })
        setIsActiveMenu(menu)
    }

    function feedback() {
        window.location.href = 'https://fdbck.me/2658'
    }

    /*Shisha menu*/
    const handleShishaMenu = () => {
        setShowShishaModal(true)
        setIsActiveMenu(3)
    };

    const handleCloseShishaModal = () => {
        setShowShishaModal(false)
        document.getElementById('scrollspyHeading44').scrollIntoView({ inline: "center" })
        setIsActiveMenu(1)
    };
    return (
        <div>
            {
                loading ? (
                    <div>Loading data...</div>
                ) : (
                    <>
                        <div className="image-container">
                            {/* <div className="overlay"></div> */}
                            <img src="../img/smoky/top_banner.webp" className="d-block w-100 top-banner background-image" alt="Background" />
                            <img src="../img/smoky/logo.webp" className="overlay-image" alt="Overlay" />
                            <button className="btn btn-warning bottom-right-button">
                                <div className='row'>
                                    <div className='col-3 p-0 text-center'>
                                        <img src="../img/smoky/feedback.png" alt="" className='p-0 alert-top-icon' />
                                    </div>
                                    <div className='col-9 p-0 text-start' onClick={feedback}>
                                        <p className='text-white fs-4 fw-bold m-0'>FEEDBACK</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="sticky-top mt-n40 pt-3 bg-body round-top">
                            <div className="row alert alert-top px-0 py-2 text-center" role="alert">
                                <div className='col-4 lh-1 m-auto'>
                                    <a href="#scrollspyHeading44" className={isActiveMenu === 1 ? 'text-decoration-none active-link' : 'text-decoration-none'} onClick={() => handleActiveMenu(1, 'scrollspyHeading44')}>
                                        <p className='text-white fs-3 fw-bold m-0'>FOOD</p>
                                    </a>
                                </div>
                                <div className='col-4 lh-1 m-auto px-0'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='vr fs-8 align-middle me-3'></div>
                                        <a href="#scrollspyHeading52" className={isActiveMenu === 2 ? 'text-decoration-none m-auto active-link' : 'text-decoration-none m-auto'} onClick={() => handleActiveMenu(2, 'scrollspyHeading52')}>
                                            <p className='text-white fs-3 fw-bold m-0'>DRINKS</p>
                                        </a>
                                        <div className='vr ms-3'></div>
                                    </div>
                                </div>
                                {/* <div className='col-4 lh-1 m-auto'>
                                    <a href="https://awjftp.blob.core.windows.net/awjcontents/QRMenuImage/shisha.webp" className='text-decoration-none' target='blank'>
                                        <p className='text-white fs-3 fw-bold m-0'>SHISHA</p>
                                    </a>
                                </div> */}
                                <div className='col-4 lh-1 m-auto'>
                                    <span className={isActiveMenu === 3 ? 'text-decoration-none m-auto active-link' : 'text-decoration-none m-auto'} target='blank' onClick={() => handleShishaMenu()}>
                                        <p className='text-white fs-3 fw-bold m-0'>SHISHA</p>
                                    </span>
                                </div>
                            </div>

                            <nav id="navbar-cat" className="navbar row p-0 mb-2 bg-white">
                                <input type="hidden" id="flag" value="0"></input>
                                <div className='col-1 p-1 bg-white'><img className='nav-img' src="../img/smoky/prev.png" alt="prev" onClick={prev} /></div>
                                <div className='col-10 px-0'>
                                    <Scrollspy
                                        items={dataRefCat.current.map((item) => ("scrollspyHeading" + item.Id))}
                                        currentClassName="active-item"
                                        className="nav nav-pills"
                                        spy={false}
                                        offset={-180}
                                        onUpdate={useOnUpdate ? (handleupdate) : (() => { })}
                                    >
                                        {
                                            dataRefCat.current.map((item) => (
                                                <li key={item.Id} className="nav-item"><a className="nav-link px-3" href={"#scrollspyHeading" + item.Id} id={"cat" + item.Id} onClick={handleOnClick}>{item.Name.toUpperCase()}</a></li>
                                            ))
                                        }
                                    </Scrollspy>
                                </div>
                                <div className='col-1 p-1 bg-white'><img className='nav-img' src="../img/smoky/next.png" alt="next" onClick={next} /></div>
                            </nav>
                        </div>
                        <div className="list-group w-auto p-3">
                            {/* <div class="alert alert-breakfast text-center" role="alert">Breakfast Menu Available From 9 AM to 12 PM</div> */}


                            {
                                dataRefMenu.current.map(function (x, i) {
                                    return (
                                        <CatMenuList key={i} data={x} desktop={desktop} dataBrand={dataRefGen.current}></CatMenuList>
                                    );
                                })

                            }
                        </div>
                        <ShishaMenuModal show={showShishaModal} handleClose={handleCloseShishaModal} />
                        <button className={showScrollButton ? 'btn btn-scroll-top' : 'btn btn-scroll-top d-none'} type="button" id='scroll-to-top' onClick={scrollToTop}> <img src='../img/smoky/top.png' alt="top" /> </button>

                    </>
                )
            }
        </div>
    )
}

export default Menu