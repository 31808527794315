import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import Qrpage from './qrpage/qrpage';
import QrpageKSA from './qrpage/qrpageksa';
import QrpageNoApp from './qrpage/qrpagenoapp';
import Menu from './menu/menu';

function App() {
  const [page, setpage] = useState('qrpage');
  const [slug, setslug] = useState('');
  const [cn, setcn] = useState('');
  const [os, setos] = useState('');
  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var pageurl = url.searchParams.get("page");
    var slugurl = url.searchParams.get("slug");
    var cngurl = url.searchParams.get("cn");
    setpage(pageurl);
    setslug(slugurl);
    setcn(cngurl);
    var userinfo = window.navigator.userAgent;
    if(userinfo.search(/android/i) !== -1) setos("android");
    else setos("ios");
  }, []);

  return (
    <div className="App shadow h-100">
      {
        (page === 'qrpage' && slug !=='' && os !=='' && cn==='ksa') ? (<QrpageKSA slug={slug}/>) : (console.log("Missing param"))
      }
      {
        (page === 'qrpage' && slug !=='' && os !=='' && cn==='uae') ? (<QrpageNoApp slug={slug}/>) : (console.log("Missing param"))
      }
      {
        (page === 'qrpage' && slug !=='' && os !=='' && cn==='') ? (<Qrpage slug={slug} os={os}/>) : (console.log("Missing param"))
      }
      {
        (page === 'menu' && slug !=='') ? (<Menu slug={slug}/>) : (console.log("Missing param"))
      }
    </div>
  );
}

export default App;