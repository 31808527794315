import React from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
function ProductModal(props) {
    const { product, show, handleClose } = props;
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="card border border-0">
                    <img src={product['Images'][0]['Image1']} className="card-img-top img-fluid" alt="..."></img>
                    <div className="card-body">
                        <h5 className="card-title text-center">{product['Names'][0]['DigitalName'].toUpperCase()}</h5>
                        <p className="card-text px-4 text-center fs-8 lh-sm">{product['Names'][0]['LongDescription']+'.'}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <p className="card-title card-text card-price fw-bold">{product['Prices'][0]['Price']} AED</p>
                <p>
                    {/* <span className='text-cal'>000 cal</span>
                    <img className="p-icon" src="../img/smoky/spicy.png" width="20" height="20" alt=''></img>
                    <img className="p-icon" src="../img/smoky/vegan.png" width="20" height="20" alt=''></img>
                    <img className="p-icon" src="../img/smoky/best.png" width="20" height="20" alt=''></img> */}
                </p>
            </Modal.Footer>
        </Modal>
    );
}
export default ProductModal