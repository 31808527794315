import React, { useState, useEffect, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';

function Qrpage(slug) {

  const [loading, setLoading] = useState(true);
  const dataRef = useRef([]);

  useEffect(() => {
    const outlet_slug = slug['slug'];
    fetch('https://qrportalbackend.azurewebsites.net/qrpage/outlet/' + outlet_slug)
      .then(response => response.json())
      .then(data => {
        dataRef.current = data;
        setLoading(false);
      })
      .catch(error => console.error(error));
      // eslint-disable-next-line
  }, []);
  const BG = {
    backgroundImage: "url('" + dataRef.current['BackgroundImage1'] + "')",
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <div className='h-100'>
      {loading ? (
        <div>Loading data...</div>
      ) : (
        <div className="container" style={BG}>
          <div className="row">
            <div className="col-12 px-0">
              <img className='img-fluid w-logo' src={dataRef.current['Logo']} alt=''></img>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-12 px-0">
            {dataRef.current['Sliders'].length !== 0 ?
              (
                <Carousel controls={false} indicators={false} touch={true} interval={3000}>
                  {
                    dataRef.current['Sliders'].map((item) => (
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={item['Image']}
                          alt="slider"
                        />
                      </Carousel.Item>
                    ))
                  }
                </Carousel>
              ):('')     
              }
            </div>
          </div>
          <div className="row pb-3 text-center">
            <div className="col-6">
              <a href={dataRef.current['LinkButton1']}><img className='img-fluid mx-auto w-btn' src={dataRef.current['ImageButton1']} alt=''></img></a>
            </div>
            <div className="col-6">
              {(slug['os'] === 'android') ? (<a href={dataRef.current['LinkButton3_1']}><img className='img-fluid mx-auto w-btn' src={dataRef.current['ImageButton3']} alt=''></img></a>) : (<a href={dataRef.current['LinkButton3_2']}><img className='img-fluid mx-auto w-btn' src={dataRef.current['ImageButton3']} alt=''></img></a>)}
            </div>
          </div>
          <div className="row text-center d-flex justify-content-center pb-3">
            <div className="col-6">
              <a href={dataRef.current['LinkButton2']}><img className='img-fluid mx-auto w-btn' src={dataRef.current['ImageButton2']} alt=''></img></a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Qrpage