import React, { useState } from 'react';
import MenuItemList from "./menu_item_list"
import ProductModal from './menu_item_modal'
function CatMenuList({ data,desktop,dataBrand }) {
    const [selectedData, setSelectedData] = useState(null);

    const handleSelectedData = (datac) => {
      setSelectedData(datac);
      setShowModal(true);
    };

    const [showModal, setShowModal] = useState(false);
      const handleCloseModal = () => {
        setShowModal(false);
      };
    return (
        <>
            <div id={"scrollspyHeading" + data.Id} className="row scroll-mt-50">
                <MenuItemList key={data.Id} data={data.Menu} onSelect={handleSelectedData} desktop={desktop}></MenuItemList>
            </div>
            <br></br>
            {selectedData!=null && <ProductModal product={selectedData} show={showModal} handleClose={handleCloseModal}/>}
        </>
    )
}

export default CatMenuList