import React from 'react'
import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
function ShishaModal(props) {
    const { show, handleClose } = props;

    return (
        <Modal show={show} onHide={handleClose} fullscreen={true} centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-dark'>Shisha Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card my-3 border-0 text-center card-shisha">
                    <img src="https://awjftp.blob.core.windows.net/awjcontents/QRMenuImage/Shisha3.webp" className="card-img-top-shisha mx-auto" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title fs-2">Smoky Classic Shisha</h5>
                        <p className="card-text">Price: 140 AED</p>
                    </div>
                    <select class="form-select mb-3" aria-label="Large select example">
                        <option selected>Flavors</option>
                        <option value="1">Blue Mint</option>
                        <option value="2">Mistika</option>
                        <option value="3">Double Apple Nakhla</option>
                        <option value="4">Double Apple Fakher</option>
                        <option value="5">Cherry</option>
                        <option value="6">Orange</option>
                        <option value="7">Blueberry</option>
                        <option value="8">Grape</option>
                        <option value="9">Sweet Melon</option>
                        <option value="10">Watermelon</option>
                        <option value="11">Mango</option>
                        <option value="12">Peach</option>
                        <option value="13">Strawberry</option>
                        <option value="14">Mint</option>
                        <option value="15">Gum</option>
                        <option value="16">Iced Lemon</option>
                        <option value="17">Iced Pineapple</option>
                        <option value="18">Double Apple Mix</option>
                        <option value="19">Citrus-Ice Mix</option>
                        <option value="20">Grape Berry</option>
                        <option value="21">Cinnamon Gum</option>
                        <option value="22">Apple Mint</option>
                        <option value="23">Lemon Mint</option>
                        <option value="24">Cherry Mint</option>
                        <option value="25">Strawberry Mint</option>
                        <option value="26">Watermelon Mint</option>
                        <option value="27">Sweet Melon Mint</option>
                        <option value="28">Orange Mint</option>
                        <option value="29">Grape Mint</option>
                        <option value="30">Gum Mint</option>
                        <option value="31">Peach Mint</option>
                        <option value="32">Blueberry Mint</option>
                        <option value="33">Mango Mint</option>
                    </select>

                </div>
                <div className="card my-3 border-0 text-center card-shisha">
                    <img src="https://awjftp.blob.core.windows.net/awjcontents/QRMenuImage/Shisha1.webp" className="card-img-top-shisha mx-auto" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title fs-2">Smoky Special Shisha</h5>
                        <p className="card-text">Price: 180 AED - Table Top</p>
                    </div>
                    <select class="form-select mb-3 fs-12px">
                        <option selected>Flavors</option>
                        <option value="1">Hawaii – Iced mango, sweet melon, wild berry, mint</option>
                        <option value="2">Pina Colada – Iced pineapple, vanilla, orange cream, coconut. </option>
                        <option value="3">Mama Mia – Ice watermelon, kiwi, raspberry</option>
                        <option value="4">Love 66 -  Mint, passion fruit, rose, sweet melon, blueberry. </option>
                        <option value="5">Berry Sorbet – Mixed berries with ice</option>
                        <option value="6">Belgian Waffle – Belgian waffles dessert</option>
                        <option value="7">Mi Amor – Banana, berry mix, mint, gum</option>
                        <option value="8">Moscow Evenings – Mango, grapefruit, watermelon, peach</option>
                        <option value="9">Mirage – Ice, cantaloupe, dragon fruit, papaya, passion fruit. </option>
                        <option value="10">Punkman – Raspberry, Strawberry, Sour cherry, Blood orange. </option>
                        <option value="11">Sky Fall – Pineapple, grapes, watermelon, peach, strawberry. </option>
                        <option value="12">1001 Nights – Pineapple, mint, banana, kiwi, apricot, black grapes. </option>
                        <option value="13">English Lord -mango, mint, peach, raspberry</option>
                        <option value="14">Havana – Ice, orange, strawberry, black grapes, red apple</option>
                        <option value="15">Lady Killer – Mango, mint, ice, peach</option>
                        <option value="16">Marbella – Peach, cherry, sweet melon with a touch of white chocolate. </option>
                        <option value="17">African Queen – A fusion of 16 African fruits. </option>
                        <option value="18">Falling Star – Mango, passion fruit</option>
                        <option value="19">Polar Cream – Pistachio ice cream</option>
                        <option value="20">Bounty Hunter – Coconut Bounty ice cream</option>
                        <option value="21">Mango Lassi – Mango with yoghurt</option>
                        <option value="22">Cosmo Flower – Flowers with blueberry</option>
                        <option value="23">Darkside Cola – Coca-Cola flavor</option>
                        <option value="24">Sweet Comet – Cranberry and banana</option>
                    </select>
                </div>
                <div className="card my-3 border-0 text-center card-shisha">
                    <img src="https://awjftp.blob.core.windows.net/awjcontents/QRMenuImage/Shisha4.webp" className="card-img-top-shisha mx-auto" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title fs-2">Smoky Signature Shisha</h5>
                        <p className="card-text">Price: 200 AED</p>
                    </div>
                    <select class="form-select mb-3 fs-12px">
                        <option selected>Flavors</option>
                        <option value="1">Hawaii – Iced mango, sweet melon, wild berry, mint</option>
                        <option value="2">Pina Colada – Iced pineapple, vanilla, orange cream, coconut. </option>
                        <option value="3">Mama Mia – Ice watermelon, kiwi, raspberry</option>
                        <option value="4">Love 66 -  Mint, passion fruit, rose, sweet melon, blueberry. </option>
                        <option value="5">Berry Sorbet – Mixed berries with ice</option>
                        <option value="6">Belgian Waffle – Belgian waffles dessert</option>
                        <option value="7">Mi Amor – Banana, berry mix, mint, gum</option>
                        <option value="8">Moscow Evenings – Mango, grapefruit, watermelon, peach</option>
                        <option value="9">Mirage – Ice, cantaloupe, dragon fruit, papaya, passion fruit. </option>
                        <option value="10">Punkman – Raspberry, Strawberry, Sour cherry, Blood orange. </option>
                        <option value="11">Sky Fall – Pineapple, grapes, watermelon, peach, strawberry. </option>
                        <option value="12">1001 Nights – Pineapple, mint, banana, kiwi, apricot, black grapes. </option>
                        <option value="13">English Lord -mango, mint, peach, raspberry</option>
                        <option value="14">Havana – Ice, orange, strawberry, black grapes, red apple</option>
                        <option value="15">Lady Killer – Mango, mint, ice, peach</option>
                        <option value="16">Marbella – Peach, cherry, sweet melon with a touch of white chocolate. </option>
                        <option value="17">African Queen – A fusion of 16 African fruits. </option>
                        <option value="18">Falling Star – Mango, passion fruit</option>
                        <option value="19">Polar Cream – Pistachio ice cream</option>
                        <option value="20">Bounty Hunter – Coconut Bounty ice cream</option>
                        <option value="21">Mango Lassi – Mango with yoghurt</option>
                        <option value="22">Cosmo Flower – Flowers with blueberry</option>
                        <option value="23">Darkside Cola – Coca-Cola flavor</option>
                        <option value="24">Sweet Comet – Cranberry and banana</option>
                    </select>
                </div>
                <div className="card my-3 border-0 text-center card-shisha">
                    <img src="https://awjftp.blob.core.windows.net/awjcontents/QRMenuImage/Shisha2.webp" className="card-img-top-shisha mx-auto" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title fs-2">Smoky Premium Shisha</h5>
                        <p className="card-text">Price: 280 AED</p>
                    </div>
                    <select class="form-select mb-3">
                        <option selected>Flavors</option>
                        <option value="1">Brusko Herbal – Cherry lemonade</option>
                        <option value="2">Brusko Herbal – Grapefruit with raspberry</option>
                        <option value="3">Brusko Herbal – Melon with coconut and caramel</option>
                        <option value="4">Brusko Herbal – Cuba Libre</option>
                        <option value="5">Brusko Herbal – Iced currant</option>
                        <option value="6">Brusko Herbal – Mango with ice</option>
                        <option value="7">Brusko Herbal – Mojito</option>
                        <option value="8">Brusko Herbal – Papaya</option>
                        <option value="9">Brusko Herbal – Fruit dragee</option>
                        <option value="10">Brusko Herbal – Mint chocolate</option>
                        <option value="11">Brusko Tobacco – Bubble gum</option>
                        <option value="12">Brusko Tobacco – Barberry</option>
                        <option value="13">Brusko Tobacco – Cinnamon dessert</option>
                        <option value="14">Brusko Tobacco – Lemon waffles</option>
                        <option value="15">Brusko Tobacco – Nut cappuccino</option>
                        <option value="16">Brusko Tobacco – Iced Cola</option>
                    </select>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default ShishaModal